import React, { FC } from "react";
import { IFilter } from "../types/IFilter";

interface Props {
  filters: IFilter[];
  setFilters: any;
}

export const FilterTags: FC<Props> = ({ filters, setFilters }) => {
  const removeElement = (id: number) => {
    const newFilters = filters.filter((tag: IFilter) => tag.id !== id);
    setFilters(newFilters);
  };

  return (
    <div className="flex flex-wrap mb-4">
      {filters.map(({ text, color, id }) => (
        <div
          key={id}
          className="px-4 py-2 m-1 rounded-full text-gray-500 bg-gray-100 font-semibold text-sm flex align-center items-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease"
        >
          <span
            className={`inline-block w-2 h-2 rounded-full mr-2 ${color}`}
          ></span>
          {text}
          <button
            onClick={() => removeElement(id)}
            className="bg-transparent hover focus:outline-none"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="times"
              className="w-3 ml-3"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 352 512"
            >
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </button>
        </div>
      ))}
    </div>
  );
};
