import { Link, NavLink } from "react-router-dom";
import { useAppDispatch } from "../hooks/redux";
import { changeSelectDay } from "../store/reducers/eventsSlice";
import { sendMetriks } from "../utils/metriks";

export const Footer = () => {
  const dispatch = useAppDispatch();

  const resetDay = () => {
    dispatch(changeSelectDay(false));
  };

  return (
    <footer className="border-t bg-gray-100 mt-8">
      <div className="container">
        <div className="flex flex-col md:flex-row w-full flex-wrap items-center justify-around space-y-5 md:space-y-0 py-12">
          <div className="order-1 md:order-none flex flex-col items-center md:pl-0 md:items-start">
            <NavLink
              to={"/about"}
              className="flex flex-col h-full text-gray-500 w-max font-dela dark:text-white mb-4 mb-3 md:pr-5 hover:text-red-800"
              onClick={() => sendMetriks('reachGoal','about yann')}
            >
              Кто такой Ян?
            </NavLink>
            <a
              href="mailto:admin@yanzone.ru"
              className="flex flex-col h-full text-gray-500 w-max font-dela dark:text-white mb-3 md:pr-5 hover:text-red-800"
            >
              Ты организатор?
            </a>
          </div>
          <div className="flex flex-col items-center order-3 md:order-none">
            <Link
              onClick={resetDay}
              to="/"
              className="text-gray-800 max-w-[150px] md:max-w-[200px]"
            >
              <img src="/assets/img/logo_new.png" alt="YanZone логотип" />
            </Link>
            <span className="text-xs">©YanZone 2024</span>
          </div>
          <div className="order-2 md:order-none flex flex-col items-center md:items-start">
            <div className="mb-4 pb-1 flex">
              <a
                href="https://t.me/yanzone"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center group"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="group-hover:fill-blue-400 hover-fill-blue-400 transition-colors duration-300"
                >
                  <g clipPath="url(#clip0_2195_264)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM11.3942 8.12068C10.3243 8.5657 8.18596 9.48676 4.97924 10.8839C4.45851 11.091 4.18574 11.2935 4.1609 11.4916C4.11894 11.8264 4.53815 11.9582 5.10901 12.1377C5.18667 12.1621 5.26712 12.1874 5.34961 12.2142C5.91125 12.3968 6.66676 12.6104 7.05951 12.6189C7.41578 12.6266 7.81342 12.4797 8.25242 12.1782C11.2486 10.1558 12.7952 9.13349 12.8923 9.11145C12.9608 9.09591 13.0557 9.07636 13.12 9.13352C13.1844 9.19069 13.178 9.29896 13.1712 9.328C13.1297 9.50504 11.4841 11.0349 10.6325 11.8266C10.367 12.0734 10.1787 12.2485 10.1402 12.2885C10.054 12.3781 9.96612 12.4628 9.88165 12.5442C9.35987 13.0472 8.96859 13.4244 9.90331 14.0404C10.3525 14.3364 10.712 14.5812 11.0705 14.8254C11.4622 15.0921 11.8528 15.3581 12.3581 15.6894C12.4869 15.7738 12.6099 15.8614 12.7296 15.9468C13.1854 16.2717 13.5949 16.5636 14.1007 16.5171C14.3946 16.49 14.6983 16.2136 14.8525 15.3893C15.2169 13.4412 15.9332 9.22025 16.0987 7.48089C16.1132 7.3285 16.095 7.13347 16.0803 7.04786C16.0657 6.96225 16.035 6.84027 15.9238 6.74997C15.792 6.64303 15.5885 6.62047 15.4975 6.62208C15.0838 6.62937 14.449 6.85008 11.3942 8.12068Z"
                      fill="#B3B3B3;"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2195_264">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="ml-2 font-dela text-gray-500 group-hover:text-blue-400 transition-colors duration-300" onClick={() => sendMetriks('reachGoal','telegram open')}>
                  Наш Telegram канал
                </span>
              </a>
            </div>
            <div className="mb-3 pb-1 flex">
              <a
                href="https://youtube.com/@yansotty"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center group"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hover-fill-red group-hover:fill-red-800 transition-colors duration-300"
                >
                  <g clipPath="url(#clip0_501_13)">
                    <path
                      d="M17.4309 3.21616H4.56912C2.04566 3.21616 0 5.26182 0 7.78527V14.2148C0 16.7383 2.04566 18.7839 4.56912 18.7839H17.4309C19.9543 18.7839 22 16.7383 22 14.2148V7.78527C22 5.26182 19.9543 3.21616 17.4309 3.21616ZM14.3408 11.3129L8.32495 14.1821C8.16465 14.2585 7.97949 14.1416 7.97949 13.9641V8.04631C7.97949 7.86621 8.16952 7.74948 8.33016 7.8309L14.346 10.8794C14.5249 10.9701 14.5218 11.2266 14.3408 11.3129Z"
                      fill="#B3B3B3;"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_501_13)">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="ml-2 font-dela text-gray-500 group-hover:text-red-800 transition-colors duration-300" onClick={() => sendMetriks('reachGoal','youtube open')}>
                  Наш YouTube
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
