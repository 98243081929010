import "./App.css";
import { AppRouter } from "./components/AppRouter";
import { useLocation } from "react-router";
import { sendMetriks } from "./utils/metriks";
// import { MainPage } from "./pages/MainPage";

function App() {
  const location = useLocation();  
  sendMetriks('hit', location.pathname);
  return (
    <div>
      <AppRouter />
    </div>
  );
}

export default App;
