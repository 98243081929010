import { FC, useEffect, useState } from "react";
import { IEvent } from "../types/IEvent";
import { EventCard } from "./EventCard";
import { Error } from "./Error";

// Import Swiper
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { EventCardSlide } from "./EventCardSlide";

interface EventsSliderProps {
  events: IEvent[];
}

export const EventsSlider: FC<EventsSliderProps> = ({ events }) => {
  return (
    <div className="events-slider">
      {events.length ? (
        <>
          <Swiper
            modules={[Navigation]}
            spaceBetween={15}
            slidesPerView={1.3}
            navigation={true}
          >
            {events.map((event) => (
              <SwiperSlide key={event.id}>
                {event && (
                  <EventCardSlide
                    id={event.id}
                    is_active={event.is_active}
                    name={event.name}
                    description={event.description}
                    date={event.date}
                    image={event.image}
                    image_event={event.image_event}
                    price={event.price}
                    address={event.address}
                    category={event.category}
                    event_type={event.event_type}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : (
        <Error />
      )}
    </div>
  );
};

{
  /* <>
          <div className="arrows arrow__left" onClick={goToPrevious}>
            <svg className="arrow__icon" viewBox="0 0 16 16">
              <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
            </svg>
          </div>
          <ul className="event__slider">
            <EventCard
              id={events[currentIndex].id}
              is_active={events[currentIndex].is_active}
              name={events[currentIndex].name}
              description={events[currentIndex].description}
              date={events[currentIndex].date}
              image={events[currentIndex].image}
              price={events[currentIndex].price}
              address={events[currentIndex].address}
              event_type={events[currentIndex].event_type}
            />
          </ul>
          <div className="arrows arrow__right" onClick={goToNext}>
            <svg className="arrow__icon" viewBox="0 0 16 16">
              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
            </svg>
          </div>
        </> */
}
