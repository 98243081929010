import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "./Avatar";
import Search from "./Search";
import { useAppDispatch } from "../hooks/redux";
import { changeSelectDay } from "../store/reducers/eventsSlice";
import {
  Button,
  Checkbox,
  Drawer,
  IconButton,
  Input,
  Typography,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Navbar } from "../components/Navbar";
import { sendMetriks } from "../utils/metriks";

export const Header = () => {
  const [openRight, setOpenRight] = React.useState(false);

  const openDrawerLogin = () => setOpenRight(true);
  const closeDrawerLogin = () => setOpenRight(false);

  const isAuth = false;

  const dispatch = useAppDispatch();

  const resetDay = () => {
    dispatch(changeSelectDay(false));
  };

  return (
    <>
      <header className="w-screen body-font mx-auto">
        <div className="container">
          <div className="flex flex-row logo justify-between items-center pt-4 pb-4 w-auto pl-2 pr-2">
            <Link
              onClick={resetDay}
              to="/"
              className="text-gray-800  2xl:max-w-[200px] xl:max-w-[100px] max-w-[200px] shrink-0"
            >
              <img
                src="/assets/img/logo_new.png"
                srcSet="/assets/img/logo_new@2x.png 2x"
                alt="Главная страница Yapzone"
                title="Перейти на главную страницу Yapzone"
              />
            </Link>
            <div className="flex items-center w-full justify-between 2xl:pl-20 xl:pl-4">
              <Navbar />
              <div className="flex self-center lg:self-end lg:mb-2">
                <div className="border-r-2 border-gray-300 md:mr-3 pr-3 pb-1">
                  <a
                    href="mailto:admin@yanzone.ru"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => sendMetriks('reachGoal','mailto open')}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="hover-fill-blue-400"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11 12.661L8.72024 10.7711L0.766556 18.9131H21.0313L13.2241 10.7573L11 12.661ZM14.3619 9.89314L21.9257 17.7574C21.9691 17.6048 22 17.4467 22 17.2796V3.68637L14.3619 9.89314ZM0 3.65476V17.2796C0 17.4467 0.0309387 17.6048 0.0742512 17.7574L7.66356 9.91856L0 3.65476ZM21.3125 2.40625H0.6875L11 10.6693L21.3125 2.40625Z"
                        fill="#B3B3B3;"
                      />
                    </svg>
                  </a>
                </div>
                <div className="pl-3 md:pl-0 border-r-2 border-gray-300 lg:mr-7 pr-3 pb-1">
                  <a
                    href="https://t.me/yanzone"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => sendMetriks('reachGoal','telegram open')}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="hover-fill-blue-400"
                    >
                      <g clipPath="url(#clip0_2195_264)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM11.3942 8.12068C10.3243 8.5657 8.18596 9.48676 4.97924 10.8839C4.45851 11.091 4.18574 11.2935 4.1609 11.4916C4.11894 11.8264 4.53815 11.9582 5.10901 12.1377C5.18667 12.1621 5.26712 12.1874 5.34961 12.2142C5.91125 12.3968 6.66676 12.6104 7.05951 12.6189C7.41578 12.6266 7.81342 12.4797 8.25242 12.1782C11.2486 10.1558 12.7952 9.13349 12.8923 9.11145C12.9608 9.09591 13.0557 9.07636 13.12 9.13352C13.1844 9.19069 13.178 9.29896 13.1712 9.328C13.1297 9.50504 11.4841 11.0349 10.6325 11.8266C10.367 12.0734 10.1787 12.2485 10.1402 12.2885C10.054 12.3781 9.96612 12.4628 9.88165 12.5442C9.35987 13.0472 8.96859 13.4244 9.90331 14.0404C10.3525 14.3364 10.712 14.5812 11.0705 14.8254C11.4622 15.0921 11.8528 15.3581 12.3581 15.6894C12.4869 15.7738 12.6099 15.8614 12.7296 15.9468C13.1854 16.2717 13.5949 16.5636 14.1007 16.5171C14.3946 16.49 14.6983 16.2136 14.8525 15.3893C15.2169 13.4412 15.9332 9.22025 16.0987 7.48089C16.1132 7.3285 16.095 7.13347 16.0803 7.04786C16.0657 6.96225 16.035 6.84027 15.9238 6.74997C15.792 6.64303 15.5885 6.62047 15.4975 6.62208C15.0838 6.62937 14.449 6.85008 11.3942 8.12068Z"
                          fill="#B3B3B3;"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2195_264">
                          <rect width="22" height="22" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
                <div className="pl-3 lg:pl-0 ml-6 lg:ml-2 mb-2">
                  <Search />
                </div>
              </div>
            </div>
            {/* <>
              {isAuth ? (
                <Avatar />
              ) : (
                <Button
                  onClick={openDrawerLogin}
                  variant="outlined"
                  className="flex items-center gap-3 rounded-full px-2 py-2 md:px-4 md:py-3"
                  color="blue-gray"
                >
                  Войти
                  <ArrowRightCircleIcon strokeWidth={2} className="h-5 w-5" />
                </Button>
              )}
            </> */}
          </div>
        </div>
      </header>

      <Drawer
        placement="right"
        open={openRight}
        onClose={closeDrawerLogin}
        className="p-4"
      >
        <div className="mb-6 flex items-center justify-between">
          <Typography variant="h5" color="blue-gray">
            Вход
          </Typography>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={closeDrawerLogin}
          >
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </div>

        <form className="flex flex-col gap-6">
          <div className="flex flex-col gap-6">
            <Input size="lg" label="Почта" />
            <Input type="password" size="lg" label="Пароль" />
          </div>

          <Button disabled fullWidth color="blue-gray">
            Вход
          </Button>
          <Typography color="gray" className="text-center font-normal">
            Нет аккаунта?{" "}
            <a
              href="#"
              className="font-medium text-blue-500 transition-colors hover:text-blue-700"
            >
              Регистрация
            </a>
          </Typography>
        </form>
      </Drawer>
    </>
  );
};
