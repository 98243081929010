import { getCalendar } from "../helpers/getCalendar";
import React, { FC, useRef, useEffect, useState, RefObject } from "react";
import { DateMonth } from "./DateMonth";
import { Button } from "./Button";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "../hooks/redux";

interface DateFilterProps {
  monthTitleRef?: RefObject<HTMLDivElement>;
}

export const DateList: FC<DateFilterProps> = ({ monthTitleRef }) => {
  // Получаем календарь за три месяца
  const calendar = getCalendar(3);

  // Для драга месяцов
  const [initialMouseX, setInitialMouseX] = useState<number | null>(null);
  const [initialScrollLeft, setInitialScrollLeft] = useState<number | null>(
    null
  );
  const [scrollPosition, setScrollPosition] = useState<number>(
    0
  );

  // Scroll block
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        // FIXME: Остановился тут, почему то не обновляет scrollPosition
        console.log(scrollContainer.scrollLeft);
        
        setScrollPosition(scrollContainer.scrollLeft);
        console.log("Current scroll position:", scrollPosition);
        // Use the scroll position as needed
      }
    };

    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleArrowLeftClick = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.style.scrollBehavior = "smooth";
      scrollContainer.scrollLeft += 300;
      setTimeout(() => {
        scrollContainer.style.scrollBehavior = "auto";
      }, 100);
    }
  };

  const handleArrowRightClick = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.style.scrollBehavior = "smooth";
      scrollContainer.scrollLeft -= 300;
      setTimeout(() => {
        scrollContainer.style.scrollBehavior = "auto";
      }, 100);
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setInitialMouseX(event.clientX);
    setInitialScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (initialMouseX !== null && initialScrollLeft !== null) {
      const delta = event.clientX - initialMouseX;
      scrollContainerRef.current!.scrollLeft = initialScrollLeft - delta;
    }
  };

  const handleMouseUp = () => {
    setInitialMouseX(null);
    setInitialScrollLeft(null);
  };

  return (
    <div className="relative pb-4">
      <Button
        onClick={handleArrowRightClick}
        className="absolute top-28 md:top-[40px] left-0 md:-left-[60px] group border-none"
      >
        <ChevronLeftIcon className="h-6 w-6 text-red-500 group-hover:text-white group-focus:text-white" />
      </Button>
      <div
        ref={scrollContainerRef}
        className="flex gap-10 overflow-auto md:overflow-hidden pb-10"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        {calendar.map((month, idx) => (
          <DateMonth
            key={idx}
            nameMonth={month.name}
            days={month.days}
            monthTitleRef={monthTitleRef}
            initialScrollLeft={initialScrollLeft}
          />
        ))}
      </div>
      <Button
        onClick={handleArrowLeftClick}
        className="absolute top-28 md:top-[40px] right-0 md:-right-[60px] group border-none"
      >
        <ChevronRightIcon className="h-6 w-6 text-red-500 group-hover:text-white group-focus:text-white " />
      </Button>
    </div>
  );
};
