import { AppDispatch, RootState } from "../store";
import { eventsSlice } from "./eventsSlice";
import { changeSelectDay } from "./eventsSlice";
import { categoriesSlice } from "./categoryReduser";
import axiosInstance from "../../helpers/axiosInstance";
import dayjs from "dayjs";

export const fetchEvents =
  (category: string | undefined) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(eventsSlice.actions.eventsFetching());
      const currentDate = dayjs().format("YYYY-MM-DD");

      if (typeof category === "string") {
        const {
          categoryReduser: { categories },
        } = getState();

        const idCategory = categories.find(
          (item) => item.name === category
        )?.id;
        if (idCategory === undefined) throw new Error("no category find");

        const responseEvents = await axiosInstance.get(
          `/api/events-category/${idCategory}/?start_date=${currentDate}&limit=999`
        );
        dispatch(
          eventsSlice.actions.eventsFetchingSuccess(responseEvents.data.results)
        );
      } else {
        const responseEvents = await axiosInstance.get(
          `/api/event-all/?start_date=${currentDate}&limit=999`
        );
        dispatch(
          eventsSlice.actions.eventsFetchingSuccess(responseEvents.data.results)
        );
      }

      dispatch(changeSelectDay(false));
      dispatch(eventsSlice.actions.colorsFetching());
      const responseColors = await axiosInstance.get("/api/date-colors/");

      dispatch(eventsSlice.actions.colorsFetchingSuccess(responseColors.data));
    } catch (e) {
      console.error(e);
    }
  };

export const fetchCategories = () => async (dispatch: AppDispatch) => {
  try {
    const responseCategories = await axiosInstance.get("/api/category/");
    dispatch(categoriesSlice.actions.setCategories(responseCategories.data));
  } catch (e) {
    console.log(e);
  }
};

export const fetchEventsSearch =
  (searchStr: string) => async (dispatch: AppDispatch) => {
    try {
      const searchEvents = await axiosInstance.get(
        `/api/events-filter/${searchStr}/`
      );
      dispatch(eventsSlice.actions.findEventsByStr(searchEvents.data.results));
    } catch (e) {
      console.log(e);
    }
  };
