import { FC } from "react";
import { IEvent } from "../types/IEvent";
import { EventCard } from "./EventCard";
import { IFilter } from "../types/IFilter";

interface EventsListProps {
  events: IEvent[];
  filters: IFilter[];
}

export const EventsList: FC<EventsListProps> = ({ events, filters }) => {
  let filteredEvents = events;

  filters.forEach(({ field, value }) => {
    filteredEvents = filteredEvents.filter((event) => event[field] === value);
  });

  return (
    <div>
      {filteredEvents.length ? (
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredEvents.map((event) => (
            <EventCard
              key={event.id}
              id={event.id}
              is_active={event.is_active}
              name={event.name}
              description={event.description}
              date={event.date}
              image={event.image}
              image_event={event.image_event}
              price={event.price}
              address={event.address}
              category={event.category}
              owner={event.owner}
              event_type={event.event_type}
            />
          ))}
        </ul>
      ) : (
        <div className="bg-gray-600 py-8 md:px-20 md:py-20 mt-20 mx-auto rounded-lg flex flex-col items-center text-center">
          <h2 className="text-white text-4xl md:text-5xl tracking-tight">
            К сожалению, событий пока нет
          </h2>
          <p className="text-gray-400 mt-4 text-lg md:text-xl">
            Попробуйте выбрать другой день или изменить фильтры
          </p>
        </div>
      )}
    </div>
  );
};
