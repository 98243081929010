// First we need to import axios.js
import axios from "axios";
// Next we make an 'instance' of it
const axiosInstance = axios.create({
  baseURL: "https://api.yanzone.ru",
});

// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

export default axiosInstance;
