import { DateList } from "../components/DateList";
import { EventsList } from "../components/EventsList";
import { Title } from "../components/Title";
import { EventsSlider } from "../components/EventsSlider";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { fetchEvents } from "../store/reducers/actionCreators";
import { useEffect, useRef, useState } from "react";
import { FilterDropdown } from "../components/FilterDropdown";
import { FilterTags } from "../components/FilterTags";
import Layout from "../layouts/Layout";
import { useParams } from "react-router-dom";
import { IFilter } from "../types/IFilter";
import { findEventsOnDay } from "../store/reducers/eventsSlice";

export const MainPage = () => {
  const dispatch = useAppDispatch();
  const {
    filteredEvents,
    selectDay,
    events: AllEvents,
  } = useAppSelector((state) => state.eventsReducer);

  const { categories } = useAppSelector((state) => state.categoryReduser);
  const [titleText, setTitleText] = useState<string>("Рекомендуем посетить");

  const { category } = useParams();
  useEffect(() => {
    dispatch(fetchEvents(category));
  }, [dispatch, categories, category]);

  useEffect(() => {
    if (category) {
      setTitleText(category);
    } else if (!selectDay ) {
      setTitleText("Рекомендуем посетить");
    } else {
      setTitleText("События в выбранный день");
    }
  }, [selectDay, category]);

  const allFilters: IFilter[] = [
    {
      id: 1,
      text: "Онлайн",
      color: "bg-purple-600",
      field: "event_format",
      value: "Online",
    },
    {
      id: 2,
      text: "На французском языке",
      color: "bg-red-600",
      field: "language",
      value: "French",
    },
    {
      id: 3,
      text: "Бесплатно",
      color: "bg-orange-400",
      field: "price",
      value: null,
    },
  ];

  const monthTitleRef = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useState<IFilter[]>([]);

  return (
    <Layout>
      <div className="container w-full flex flex-col">
        <div className="flex items-center justify-between">
          <Title
            text="Афиша французских событий в Москве"
            level={1}
            className="text-2xl md:text-4xl font-dela pb-4 ml-2 mb-2"
          />
          <FilterDropdown
            allFilters={allFilters}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
        <FilterTags filters={filters} setFilters={setFilters} />
        <div className="main__calendar">
          {/* <div className="relative hidden md:block">
            <p className="text-2xl font-medium mb-2 text-gray-800 absolute top-0 left-2" ref={monthTitleRef}>
              Август
            </p>
          </div> */}
          <DateList monthTitleRef={monthTitleRef} />
        </div>

        {!selectDay && !filteredEvents && (
          <div className="main__events mt-16">
            <Title
              text="Ближайшие события"
              level={2}
              className="text-2xl md:text-5xl font-semibold mb-8"
            />
            <EventsSlider events={filteredEvents} />
          </div>
        )}
        <div className="main__recommend">
          <Title
            text={titleText}
            level={2}
            className="text-2xl md:text-4xl mb-8 font-dela"
          />

          <EventsList events={filteredEvents} filters={filters} />
          {filteredEvents.length <= 0 && AllEvents.length > 0 && (
            <div className="main__events mt-20">
              <Title
                text="Другие события"
                level={2}
                className="text-2xl md:text-5xl font-semibold mb-8"
              />
              <EventsSlider events={AllEvents} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
