import React, { useState, FC, SetStateAction, Dispatch } from "react";
import { IFilter } from "../types/IFilter";

interface Props {
  allFilters: IFilter[];
  filters: IFilter[];
  setFilters: Dispatch<SetStateAction<IFilter[]>>;
}

export const FilterDropdown: FC<Props> = ({
  allFilters,
  filters,
  setFilters,
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleToggle = () => setIsActive(!isActive);

  const addElement = (id: number) => {
    if (!filters.find((filter) => filter.id === id)) {
      setFilters(
        filters.concat(allFilters.filter((filter) => filter.id === id))
      );
    }
    setIsActive(false);
  };

  return (
    <div className="inline-flex border-2 border-gray-300 rounded-md mb-4 bg-gray-100 rounded-lg h-10">
      <button
        onClick={handleToggle}
        className="px-4 py-2 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-l-md"
      >
        <div className="hidden md:flex items-center">
          <span className="text-gray-400 font-montserratAlternates">Фильтры</span>
          <span className="flex items-center justify-center h-full ml-2 pl-2 text-gray-600 border-l border-gray-100 hover:text-gray-700 rounded-r-md hover:bg-gray-50">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={isActive ? "w-4 h-4 rotate-180" : "w-4 h-4"}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </div>
        <div className="block md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
        </div>
      </button>
      {isActive ? (
        <div className="relative">
          <div className="absolute right-0 top-8 z-10 w-56 mt-4 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg">
            <div className="p-2">
              {allFilters.map(({ color, text, id }) => (
                <div
                  key={id}
                  onClick={() => addElement(id)}
                  className="cursor-pointer block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                >
                  <span
                    className={`inline-block w-2 h-2 rounded-full mr-2 ${color}`}
                  />
                  {text}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
