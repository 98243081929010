import React, { useState, FC, useRef } from "react";
import { useDetectOutsideClick } from "../hooks/useDetectOutsideClick";
import { NavbarItem } from "./Navbar";
import { getClassNameForNavItem } from "../helpers";

interface NavbarDropdownProps {
  text: string;
  items: NavbarItem[];
  className?: string;
  onDropdownClick: (value: number, name: string) => void;
}

export const NavbarDropdown: FC<NavbarDropdownProps> = ({
  text,
  items,
  onDropdownClick,
  className,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useDetectOutsideClick(ref, () => setIsOpen(false));
  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <div
      className={`flex items-center h-full text-gray-900 dark:text-white font-semibold hover:text-indigo-700 py-3 ${className}`}
      ref={ref}
    >
      <button
        onClick={handleToggle}
        className="px-4 py-2 text-sm text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded-l-md"
      >
        <div className="flex items-center text-xl">
          {text}

          <span className="flex items-center justify-center h-full ml-2 pl-2 text-gray-600 border-l border-gray-100 hover:text-gray-700 rounded-r-md hover:bg-gray-50">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={isOpen ? "w-4 h-4 rotate-180" : "w-4 h-4"}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </div>
        {/* <div className="block md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
            />
          </svg>
        </div> */}
      </button>
      {isOpen ? (
        <div className="relative">
          <div className="absolute right-0 top-8 z-10 w-56 mt-4 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg">
            <div className="p-2 flex flex-col gap-1">
              {items.map((item) => (
                <button
                  key={item.id}
                  className={`block px-4 py-2 text-base text-gray-900 hover:bg-gray-50 rounded-t-lg hover:text-gray-700 border-b-2   transition-all ${getClassNameForNavItem(item.name)}`}
                  onClick={()=>onDropdownClick(item.id, item.name)}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
