import "dayjs/locale/ru";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(localeData);

export const getCalendar = (months: number) => {
  dayjs.locale("ru");
  const dayJsLocale = dayjs().localeData();
  const allMonths = dayJsLocale.months();
  const calendar = [];
  const today = dayjs().date();

  const WEEK_DAYS = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];

  for (let i = 0; i < months; i++) {
    // Create a new dayjs object for each iteration
    const currentMonth = dayjs().add(i, "month");

    // номер месяца 0 - 11
    const numberMonth = currentMonth.month();

    // Кол-во дней в текущем месяце
    // const numberDays = currentMonth.daysInMonth();

    // название текущего месяца
    let nameCurrentMonth = allMonths[numberMonth];

    // с заглавной буквы
    nameCurrentMonth =
      nameCurrentMonth.charAt(0).toUpperCase() + nameCurrentMonth.slice(1);

    const days = [];

    // последний день месяца
    const endMonthDate = currentMonth.endOf("month").date();

    // если текущий месяц
    if (i === 0) {
      for (let day = today; day <= endMonthDate; day++) {
        // пушим дату
        days.push({
          num: day,
          format: currentMonth.date(day).format("YYYY-MM-DD"),
          weekDay: WEEK_DAYS[currentMonth.date(day).day()],
        });
      }
    } else {
      // все остальные месяца
      for (let day = 1; day <= endMonthDate; day++) {
        // пушим дату
        days.push({
          num: day,
          format: currentMonth.date(day).format("YYYY-MM-DD"),
          weekDay: WEEK_DAYS[currentMonth.date(day).day()],
        });
      }
    }

    calendar.push({
      num: numberMonth,
      name: nameCurrentMonth,
      days,
    });
  }

  return calendar;
};
