import React from "react";
import Layout from "../layouts/Layout";
import YanSottyImage from "../assets/imgYan.png";

interface QuestionProps {
  title: string;
  content: string;
}

const questions: QuestionProps[] = [
  {
    title: "Кто я?",
    content:
      "Bonjour les amis! Меня зовут Ян Сотти. Я – француз, живущий в Москве уже на протяжении четверти века, стану вашим проводником во французский мир столицы России и по стране в целом! Меня иногда считают настоящим амбассадором французских событий в Москве. Ко мне часто обращаются не только представители французского сообщества в Москве, зная, что я всегда порекомендую как интересно провести время, но и российские любители всего французского.",
  },
  {
    title: "Чем занимаюсь?",
    content:
      "Я ведущий программы «Давай!», рассказывающей о предпочитающих жить и работать в России французах, и теперь автор проекта и передачи YanZone -Комильфо!  Я точно знаю, где в Москве купить французские товары, посмотреть кино на французском, попробовать настоящую французскую кухню, отведать сыры, неотличимые от тех, что вы встречали во Франции. ",
  },
  {
    title: "О проекте",
    content:
      "Я живу в России с 2001 года и очень люблю эту страну, но за это время я не забыл о своих французских корнях. Благодаря моей экспертизе вы сможете окунуться в неповторимую французскую атмосферу, и для этого совсем необязательно покупать билет на самолет до Парижа. Специально для вас мы с моей командой отбираем самые интересные события культурной жизни Москвы с французским акцентом, которые заслуживают нашего одобрения и получают знак качества «Комильфо».",
  },
];

const Question: React.FC<QuestionProps> = ({ title, content }) => (
  <div>
    <h2>{title}</h2>
    <p>{content}</p>
  </div>
);

export const YanPage = () => {
  return (
    <Layout>
      <div className="flex flex-col">
        <section className="container w-full">
          <div className="flex question">
            <div className="flex flex-wrap">
              <h1>КТО ТАКОЙ ЯН?</h1>
              <div className="flex flex-wrap mt-7">
                {questions.map((q, index) => (
                  <Question key={index} {...q} />
                ))}
              </div>
            </div>
            <div className="flex-shrink-0 mt-7">
              <img
                src={YanSottyImage}
                alt="Yan Sotty"
                width={"350"}
                height={"450"}
              />
            </div>
          </div>
        </section>
        <section className="video_section">
          <div className="flex container">
            <div className="pl-7 flex flex-nowrap video_text">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fqGfI1EEVmo?si=iRmSQeqjzGIYhNX9"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
              <div className="flex flex-wrap flex-col justify-center">
                <p className="text-5xl font-dela video_section_text">“</p>
                <p className="text-white text-xl font-dela">
                  Уверен, что каждый любитель Франции и всего французского
                  найдет для себя занятие по душе, а YanZone вам в этом поможет!
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};
