import { FC, useEffect, useState } from "react";
import { createDateFromProps } from "../helpers/createDateFromProps";
import { IEvent, IEventType } from "../types/IEvent";
import { Title } from "./Title";
import defaultImg from "../assets/defaultImg.jpg";
import { Link } from "react-router-dom";
import { axiosInstance } from "../helpers";
import { sendMetriks } from "../utils/metriks";

export const EventCard: FC<IEvent> = ({
  name,
  date,
  image,
  image_event,
  address,
  id,
  owner,
  event_type,
}) => {
  const [imageSrc, setImageSrc] = useState<string>(defaultImg);
  const [eventType, setEventType] = useState<string>("");
  const [organizerName, setOrganizerName] = useState<string>("");
  useEffect(() => {
    const determineImageSource = async () => {
      let eventImage = defaultImg;
      if (image) {
        eventImage = image;
      } else if (image_event) {
        eventImage = image_event;
      } else if (event_type?.image) {
        const eventTypeResponse = await axiosInstance.get(`/api/event-type/${event_type?.id}`);
        eventImage = eventTypeResponse.data.image || defaultImg;
      }
      return eventImage;
    };
    
    const setImageSource = async () => {
      const eventImage = await determineImageSource();
      setImageSrc(eventImage);
    };

    setImageSource();

    const fetchOrganizer = async () => {
      if (owner && owner.id) {
        const organizerResponse = await axiosInstance.get(`/api/organization/${owner.id}`);
        if (organizerResponse.data.name) {
          setOrganizerName(organizerResponse.data.name);
        }
      }
      if(event_type?.name){
        const eventTypeResponse = await axiosInstance.get(`/api/event-type/${event_type?.id}`);
        if (eventTypeResponse.data.name){
          setEventType(eventTypeResponse.data.name);
        }
      }
    };

    fetchOrganizer();
  }, [image_event, image, event_type, owner]);
  const showDate = createDateFromProps(date);
  return (
    <>
      <li className="flex flex-col group border-b-2 pb-2 last:border-b-0 md:border-b-0 " onClick={() => sendMetriks('reachGoal','open event')}>
        <Link
          to={`/event/${id}`}
          className="block h-[240px] transition-all group-hover:opacity-70 relative"
        >
          <img
            className="w-full h-full object-cover rounded-2xl"
            src={imageSrc}
            alt={name}
          />

          <div className="absolute p-2 bottom-2 left-2 rounded-xl text-white font-bold bg-gray-900/50 backdrop-blur-sm">
            {event_type?.name && <span>{event_type.name}</span>}
          </div>

        </Link>

        <Link to={`/event/${id}`} className="block group-hover:opacity-70">
          <div className="text-base font-semibold text-gray-400 mt-2">
            {organizerName && <span>{organizerName}</span>}
          </div>
          <Title
            className="text-base md:text-xl mb-2 font-dela"
            text={name}
            level={3}
          />

          <div className="flex flex-col text-xs md:text-base text-gray-700">
            <div className="mb-2"><p className="font-montserrat font-bold">{showDate} </p></div>
            {/* {address ? (
              <div>
                <p className="font-montserrat">{address}</p>
              </div>
            ) : null} */}
          </div>
        </Link>
      </li>
    </>
  );
};
