import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEvent } from "../../types/IEvent";

interface DateColors {
  [date: string]: string[];
}

interface EventsState {
  events: IEvent[];
  filteredEvents: IEvent[];
  findedEventsByStr: IEvent[];
  dateColors: DateColors;
  isLoading: boolean;
  error: string;
  selectDay: boolean | string;
}

const initialState: EventsState = {
  events: [],
  filteredEvents: [],
  findedEventsByStr: [],
  dateColors: {},
  isLoading: false,
  error: "",
  selectDay: false,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    eventsFetching(state) {
      state.isLoading = true;
    },
    eventsFetchingSuccess(state, action: PayloadAction<IEvent[]>) {
      state.isLoading = false;
      state.error = "";
      state.events = action.payload;
      state.filteredEvents = state.events;
    },
    colorsFetching(state) {
      state.isLoading = true;
    },
    colorsFetchingSuccess(state, action: PayloadAction<DateColors>) {
      state.isLoading = false;
      state.error = "";
      state.dateColors = action.payload;
    },
    eventsFetchingError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    findEventsOnDay(state, action: PayloadAction<string>) {
      state.filteredEvents = state.events.filter(
        (event) => event.date.split("T")[0] === action.payload
      );
    },
    findEventsOnCategory(state, action: PayloadAction<string | number>) {
      //переписать логику как только будет бэк, пока работает только категория "все"

      if (action.payload === 0) {
        state.filteredEvents = state.events;

        // убираем фильтрацию по дню
        state.selectDay = false;
      } else {
        state.filteredEvents = state.events.filter((event) => {
          // убираем фильтрацию по дню
          state.selectDay = false;

          return (
            Array.isArray(event.category) &&
            event.category.length > 0 &&
            event.category[0].id == action.payload
          );
        });
      }
    },
    changeSelectDay(state, action: PayloadAction<string | boolean>) {
      state.selectDay = action.payload;

      // при смене дня - нужна так же сбросить фильтры по категориям
      state.filteredEvents = state.events;
    },

    findEventsByStr(state, action) {
      state.findedEventsByStr = action.payload;
      state.selectDay = false; // Reset the day selection
    },
  },
});

export default eventsSlice.reducer;
export const {
  findEventsOnDay,
  findEventsOnCategory,
  changeSelectDay,
  findEventsByStr,
} = eventsSlice.actions;
