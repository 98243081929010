import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createDateFromProps } from "../helpers/createDateFromProps";
import { IEvent } from "../types/IEvent";
import { Title } from "./Title";
import { axiosInstance } from "../helpers";
import defaultImg from "../assets/defaultImg.jpg";

export const EventCardSlide: FC<IEvent> = ({ name, date, image, address, id, image_event, event_type }) => {
  const [imageSrc, setImageSrc] = useState<string>(defaultImg);
  useEffect(() => {
    const determineImageSource = async () => {
      let eventImage = defaultImg;
      if (image) {
        eventImage = image;
      } else if (image_event) {
        eventImage = image_event;
      } else if (event_type?.image) {
        const eventTypeResponse = await axiosInstance.get(`/api/event-type/${event_type?.id}`);
        eventImage = eventTypeResponse.data.image || defaultImg;
      }
      return eventImage;
    };
    
    const setImageSource = async () => {
      const eventImage = await determineImageSource();
      setImageSrc(eventImage);
    };

    setImageSource();
  }, [image_event, image, event_type]);
  const showDate = createDateFromProps(date);
  return (
    <>
      <Link to={`/event/${id}`}>
        {/* <img className="event__img" src={image ? image : defaultImg} alt={name} /> */}
        <div
          className="min-h-[250px] md:min-h-[380px] relative rounded-2xl overflow-hidden"
          style={{ backgroundImage: `url(${imageSrc})`, backgroundSize: "cover" }}
        >
          <div className="absolute p-2 left-2 bottom-2 right-2 rounded-xl bg-gray-900/50 backdrop-blur-sm">
            <Title
              className="text-base md:text-3xl font-bold text-white mb-2 md:mb-4"
              text={name}
              level={3}
            />
            <div className="flex flex-col md:flex-row text-sm md:text-base text-gray-200 ">
              <p className="">{showDate} </p>
              {/* {address ? (
                <>
                  <p className="px-2 ">•</p>
                  <p className="">{address}</p>
                </>
              ) : null} */}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};
