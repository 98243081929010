import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { findEventsOnDay } from "../store/reducers/eventsSlice";

interface DateNumProps {
  day: string;
  num: number;
  weekDay: string;
  activeButton: string | number | boolean | null;
  changeActiveButton: (data: number | string) => void;
}

export const DateNum: FC<DateNumProps> = ({
  day,
  num,
  weekDay,
  activeButton,
  changeActiveButton,
}) => {
  const dispatch = useAppDispatch();

  const onDayClickHandle = () => {
    changeActiveButton(day);

    dispatch(findEventsOnDay(day));

    // Скролл ниже
    window.scrollTo(0, 500);
  };

  const { dateColors } = useAppSelector((state) => state.eventsReducer);
  const foundColors = dateColors[day];
  
  const colorsClasses: { [key: string]: string } = {
    'Развлечения': "bg-red-600", // 5
    'Образование': "bg-purple-600", // 6
    'Для детей': "bg-orange-600", // 7
    'Гастрономия': "bg-blue-600", // 9
    'Сообщество': "bg-green-600",
  };
  
  return (
    <button
      className={`mt-4 w-12 md:w-16 h-12 md:h-8 shrink-0 hover:bg-slate-600 hover:text-red-400 transition rounded-sm relative backdrop-filter backdrop-blur-sm border-r-2 border-gray-300 ${
        activeButton === day ? "bg-white text-red" : "bg-white text-gray-700"
      }`}
      onClick={() => onDayClickHandle()}
    >
       <div className="h-[32px] md:h-[40px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col">
        {weekDay === "СБ" || weekDay === "ВС" ? (
          <span
            className={`text-xs md:text-base leading-3 tracking-widest font-bold ${
              activeButton === day ? "text-red-400" : "text-red-400"
            }`}
          >
            {weekDay}
          </span>
        ) : (
          <span
            className={`text-xs md:text-base leading-3 font-bold tracking-widest ${
              activeButton === day ? "text-red-400" : "text-gray-700"
            }`}
          >
            {weekDay}
          </span>
        )}

        <span className={`text-xs  leading-5 font-bold ${
          activeButton === day ? "text-red-400" : "text-gray-700"
        }`}>{num}</span>

        <div className="flex absolute -bottom-[3px] md:-bottom-[4px] left-1/2 transform -translate-x-1/2 font-bold">
          {foundColors &&
            foundColors.map((name) => (
              <span
                key={name}
                className={
                  "inline-block w-[6px] h-[6px] rounded-full " + colorsClasses[name]
                }
              ></span>
            ))}
        </div>
      </div>
      {activeButton === day && (
        <span
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-red-400 rounded-full opacity-20"
          style={{ zIndex: 1 }}
        ></span>
      )}
    </button>
  );
};
