import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CategoriesItems {
  id: number;
  name: string;
}

interface CategoriesState {
  categories: CategoriesItems[];
}

const initialState: CategoriesState = {
  categories: [],
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories(state, action) {
      state.categories = [
        { id: 0, name: "Хочу ВСЁ!" },
        ...action.payload.results,
      ];
    },
  },
});

export default categoriesSlice.reducer;
export const { setCategories } = categoriesSlice.actions;
