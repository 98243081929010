import React, { FC } from "react";

interface TitleProps {
  text: string;
  level: number;
  className?: string;
}

export const Title: FC<TitleProps> = ({ text, level, className }) => {
  return React.createElement(`h${level}`, { className }, text);
};
