import { FC, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { NavbarBtn } from "./NavbarBtn";
import { NavbarDropdown } from "./NavbarDropdown";
import { getClassNameForNavItem } from "../helpers";
import { useNavigate } from "react-router";
import { useParams, useLocation, Link, NavLink } from "react-router-dom";
import { fetchCategories } from "../store/reducers/actionCreators";
import { sendMetriks } from "../utils/metriks";

export interface NavbarItem {
  id: number;
  name: string;
}

export const Navbar: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { category: activeCategory } = useParams();
  const location = useLocation();

  const { categories: items } = useAppSelector(
    (state) => state.categoryReduser
  );

  // В зависисмости от разрешения - меняем кол-во элементов
  // Create a state variable to store the screen size
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  // Use useEffect to update the screenSize state when the window is resized
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const onNavbarBtnClick = (category: number, name: string) => {
    // отправляем на главную (с категорией)
    category === 0 ? navigate("/") : navigate(`/category/${name}`);

    // Скролл ниже
    window.scrollTo(0, 500);
  };

  let itemsToRender = items;
  if (screenSize < 600) {
    itemsToRender = items.slice(0, 1);
  } else if (screenSize >= 600 && screenSize < 800) {
    itemsToRender = items.slice(0, 2);
  } else if (screenSize >= 800 && screenSize <= 1000) {
    itemsToRender = items.slice(0, 3);
  } else if (screenSize < 1000) {
    itemsToRender = items.slice(0, 4);
  }

  return (
    <nav>
      <div className="max-w-screen-xl mx-auto flex items-end mobile-navbar">
        {items.length > 0 && (
          <ul className="flex flex-row justify-between mt-0 text-sm font-medium min-w-max">
            <li className="flex">
              <NavLink
                to={"/about"}
                className={({ isActive }) =>
                  `self-end inline-block md:mb-4 lg:mb-3 pr-2 text-gray-500 w-max font-dela dark:text-white text-sm md:border-r-2 border-gray-300 hover:text-red-800 ${
                    isActive ? "text-red-800" : ""
                  } nav-item`
                }
                onClick={() => sendMetriks('reachGoal','about yann')}
              >
                Кто такой Ян?
              </NavLink>
            </li>
            {itemsToRender.map((item, index) => (
              <li key={item.id}>
                <NavbarBtn
                  text={item.name}
                  onNavbarBtnClick={onNavbarBtnClick}
                  category={item.id}
                  activeLink={
                    (location.pathname === "/" && item.id === 0) ||
                    activeCategory === item.name
                      ? true
                      : false
                  }
                  isLast={index === items.length - 1}
                />
              </li>
            ))}

            {items.length !== itemsToRender.length && (
              <li>
                <NavbarDropdown
                  onDropdownClick={onNavbarBtnClick}
                  text="Ещё"
                  items={items.filter((item) => !itemsToRender.includes(item))}
                />
              </li>
            )}
          </ul>
        )}
      </div>
    </nav>
  );
};
