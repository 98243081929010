import axiosInstance from "./axiosInstance";
import { createDateList } from "./createDateList";
import { getCalendar } from "./getCalendar";
import { createDateFromProps } from "./createDateFromProps";
import { convertDate, getTime } from "./convertDate";

function getClassNameForNavItem(category: string) {
  switch (category) {
    case "Образование":
      return "border-purple-600";
      break;

    case "Развлечения":
      return "border-red-600";
      break;

    case "Сообщество":
      return "border-green-600";
      break;

    case "Для детей":
      return "border-yellow-600";
      break;

    case "Гастрономия":
      return "border-blue-600";
      break;

    default:
      break;
  }
  return "border-gray-50";
}

export {
  axiosInstance,
  getCalendar,
  createDateList,
  createDateFromProps,
  convertDate,
  getTime,
  getClassNameForNavItem,
};
