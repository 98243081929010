import { combineReducers, configureStore } from "@reduxjs/toolkit";
import eventsReducer from "./reducers/eventsSlice";
import categoryReduser from "./reducers/categoryReduser";

const rootReducer = combineReducers({
  categoryReduser,
  eventsReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
