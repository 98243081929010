import React, { FC, useState, RefObject, useRef, useEffect } from "react";
import { DateNum } from "./DateNum";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useAppDispatch } from "../hooks/redux";
import { changeSelectDay } from "../store/reducers/eventsSlice";

type DayType = {
  num: number;
  format: string;
  weekDay: string;
};

interface DateMonth {
  days: Array<DayType>;
  nameMonth: string;
  monthTitleRef?: RefObject<HTMLDivElement>;
  initialScrollLeft?: number | null;
}

export const DateMonth: FC<DateMonth> = ({
  days,
  nameMonth,
  monthTitleRef,
  initialScrollLeft,
}) => {
  // Выбранный день
  const selectDay = useSelector(
    (state: RootState) => state.eventsReducer.selectDay
  );

  const dispatch = useAppDispatch();

  const handleClickData = (data: string) => {
    // Handle the click event
    dispatch(changeSelectDay(data));
  };

  const currentMonthTitle = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (monthTitleRef && monthTitleRef.current && currentMonthTitle.current) {
      const monthTitleRect = monthTitleRef.current.getBoundingClientRect();
      const currentMonthTitleRect =
        currentMonthTitle.current.getBoundingClientRect();

      const isColliding =
        monthTitleRect.left <= currentMonthTitleRect.right &&
        monthTitleRect.right >= currentMonthTitleRect.left &&
        monthTitleRect.top <= currentMonthTitleRect.bottom &&
        monthTitleRect.bottom >= currentMonthTitleRect.top;

      console.log(initialScrollLeft);
      if (isColliding) {
        console.log("Elements are colliding!");
        // Handle collision if needed

        monthTitleRef.current.innerHTML = currentMonthTitle.current.innerHTML;
        currentMonthTitle.current.style.opacity = "0";
      }
    }
  }, [initialScrollLeft]);

  return (
    <div className="relative">
      <div className="relative w-full">
        <p
          className="text-2xl font-medium mb-2 text-gray-800 sticky left-2 top-0 inline font-montserratAlternates"
          ref={currentMonthTitle}
        >
          {nameMonth}
        </p>
      </div>

      <div className="flex">
        {days.map((day) => (
          <DateNum
            key={day.format}
            day={day.format}
            num={day.num}
            weekDay={day.weekDay}
            activeButton={selectDay}
            changeActiveButton={() => handleClickData(day.format)}
          />
        ))}
      </div>
    </div>
  );
};
