export interface IEventOwnerProfileOrganization {
  contact_email: string;
  name: string;
  name_short: string;
  description: string;
  logo: string;
  phone: string;
  telegram: string;
  vk: string;
  website: string;
}
export interface IEventType{
  id: number;
  name: string;
  image: string;
}
interface IEventOwnerProfile {
  first_name: string;
  id: number;
  last_name: string;
  organization: IEventOwnerProfileOrganization;
}

interface IEventOwner {
  email: string;
  id: number;
  profile?: IEventOwnerProfile;
}

export interface IEvent {
  id: number;
  is_active: boolean;
  name: string;
  description: string;
  date: string;
  date_end?: string;
  image: string;
  image_event: string;
  price: string;
  price_max?: string;
  address: string;
  category: {
    id: number | string;
    name: string;
  }[];
  age_rating?: string;
  owner?: IEventOwner;
  language?: string;
  custom_language?: string;
  link?: string;
  event_format?: EVENT_FORMAT;
  location?: string;
  event_type?: IEventType;
}

export enum EVENT_FORMAT {
  OFFLINE = 'Offline',
  ONLINE = 'Online'
}
