import React, { FC } from "react";

interface Props {
  type: string;
  data: any;
}

export const FromEditor: FC<Props> = ({ type, data }) => {
  if (type == "paragraph") {
    return (
      <p
        className="py-1 mb-4"
        dangerouslySetInnerHTML={{ __html: data.text }}
      ></p>
    );
  } else if (type == "List") {
    return (
      <ul className="list-disc list-inside text-gray-900 mb-8">
        {data.items.map((item: string, index: number) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
        ))}
      </ul>
    );
  } else {
    // div default
    return (
      <div
        className="py-2 mb-8"
        dangerouslySetInnerHTML={{ __html: data.text }}
      ></div>
    );
  }
};
