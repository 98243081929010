import { FC } from "react";
import clsx from "clsx";

interface NavbarBtnProps {
  text: string;
  className?: string;
  category: number;
  onNavbarBtnClick: (value: number, name: string) => void;
  activeLink: boolean;
  isLast: boolean;
}

export const NavbarBtn: FC<NavbarBtnProps> = ({
  text,
  className,
  onNavbarBtnClick,
  category,
  activeLink,
  isLast,
}) => {
  const firstWord = text.replace(/ .*/, "");
  const bottomText = text.split(" ").slice(1).join(" ");

  return (
    <button
      className={clsx(
        `flex flex-col items-start h-full font-dela dark:text-white hover:text-red-800 py-3 px-2 justify-center`,
        className,
        activeLink ? "text-red-800" : "text-gray-500"
      )}
      onClick={() => onNavbarBtnClick(category, text)}
    >
      <span className="text-xs lowercase">{firstWord}</span>
      <span
        className={clsx(
          "text-sm first-letter:capitalize pr-4",
          !isLast && "border-r-2 border-gray-300"
        )}
      >
        {bottomText}
      </span>
    </button>
  );
};
