import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  href?: string;
  target?: string;
  metricEvent?: string;
}

export const Button: FC<Props> = ({
  children,
  onClick,
  className,
  disabled,
  href,
  target
}) => {

  const buttonClasses = `flex items-center px-3 py-2 space-x-2 text-sm leading-none ${
    disabled
      ? "text-gray-400"
      : "text-gray-900 hover:text-white focus:text-white"
  } font-bold border-2 ${
    disabled
      ? "border-gray-400"
      : "border-gray-300 hover:border-gray-900 focus:border-gray-900"
  } rounded-md ${
    disabled ? "" : "hover:bg-gray-900 focus:outline-none focus:bg-gray-900"
  } transition ${className}`;

  if (href) {
    return (
      <a href={href} className={buttonClasses} target={target}>
        {children}
      </a>
    );
  }

  return (
    <button
      type="button"
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
