export const createDateList = (days: number) => {
  const dateStart = new Date().getTime();
  const oneDayInMs = 24 * 60 * 60 * 1000;

  const datesArr = [];

  for (let i = 0; i < days; i++) {
    let day = new Date(dateStart + oneDayInMs * i);
    datesArr.push(day);
  }
  return datesArr;
};
