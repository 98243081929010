import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Account from "../pages/Account";
import EventPage from "../pages/EventPage";
import Login from "../pages/Login";
import { MainPage } from "../pages/MainPage";
import { YanPage } from '../pages/YanPage';

export enum RouteNames {
  HOME = "/",
  CATEGORY = "/category/:category",
  LOGIN = "/login",
  ACCOUNT = "/account",
  EVENT_PAGE = "/event/:id",
  ABOUT_PAGE = "/about",
}

export const AppRouter = () => {
  const auth = true;
  return auth ? (
    // доступны для авторизованных
    <Routes>
      <Route path={RouteNames.HOME} element={<MainPage />} />
      <Route path={RouteNames.CATEGORY} element={<MainPage />} />
      <Route path={RouteNames.ACCOUNT} element={<Account />} />
      <Route path={RouteNames.EVENT_PAGE} element={<EventPage />} />
      <Route path="*" element={<Navigate to={RouteNames.ACCOUNT} replace />} />
      <Route path={RouteNames.ABOUT_PAGE} element={<YanPage />} />
    </Routes>
  ) : (
    // доступны для не авторизованных
    <Routes>
      <Route path={RouteNames.HOME} element={<MainPage />} />
      <Route path={RouteNames.LOGIN} element={<Login />} />
      <Route path={RouteNames.EVENT_PAGE} element={<EventPage />} />
      <Route path="*" element={<Navigate to={RouteNames.LOGIN} replace />} />
      <Route path={RouteNames.ABOUT_PAGE} element={<YanPage />} />
    </Routes>
  );
};
