import React, { useState, useRef, useEffect, useCallback } from "react";
import { fetchEventsSearch } from "../store/reducers/actionCreators";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

const SearchBox: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target as Node)
      ) {
        // Click occurred outside the search box
        setIsInputFocused(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const debouncedHandleSearch = useCallback(
    debounce((title: string) => {
      dispatch(fetchEventsSearch(title));
    }, 300),
    [dispatch]
  );

  const handleSearch = (title: string) => {
    setSearchTerm(title);

    if (title.length >= 3) {
      debouncedHandleSearch(title);
    }
  };

  const { findedEventsByStr } = useAppSelector((state) => state.eventsReducer);

  const handleIconClick = () => {
    setIsModalVisible(true);
    setIsInputFocused(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSearchTerm("");
  };

  return (
    <div className="relative text-gray-500 mb-2" ref={searchBoxRef}>
      <button
        type="submit"
        className="absolute right-0 top-0 h-full flex sm:mt-0"
        onClick={handleIconClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path>
        </svg>
      </button>
      {isModalVisible && isInputFocused && (
        <div className="absolute right-0 mt-10 bg-white border-2 border-gray-300 rounded-lg z-50 w-64 p-6 shadow-lg">
          <button
            className="absolute top-2 right-2 text-lg"
            onClick={handleModalClose}
          >
            &times;
          </button>
          <input
            className="w-full bg-gray-100 border-2 border-gray-300 h-10 px-5 pr-2 rounded-lg text-sm focus:outline-none focus:border-gray-900 font-montserratAlternates"
            type="search"
            name="search"
            placeholder="Поиск"
            onChange={(e) => handleSearch(e.target.value)}
            autoFocus
          />
          {searchTerm.length >= 3 && (
            <div className="py-3 text-sm bg-white border-gray-50 border-2 rounded-lg w-full z-20 mt-2">
              {findedEventsByStr.length > 0 ? (
                findedEventsByStr.map((event) => (
                  <Link
                    to={`/event/${event.id}`}
                    key={event.id}
                    className="flex flex-col cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2"
                    onClick={handleModalClose}
                  >
                    <div className="flex">
                      <span className="bg-red-600 h-2 w-2 m-2 rounded-full shrink-0"></span>
                      {event.event_type && (
                        <span className="text-sm font-bold text-gray-500 tracking-wide">
                          {event.event_type.name}
                        </span>
                      )}
                    </div>
                    <div className="flex-grow font-medium px-2 max-w-[160px]">
                      {event.name}
                    </div>
                  </Link>
                ))
              ) : (
                <p className="text-gray-600 text-center">
                  По такому запросу ничего не найдено
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBox;
